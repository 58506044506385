import React, { Fragment, useContext, useEffect, useState } from 'react';
import { createSocket } from '../socket';
import { AuthContext } from '../context/authContext';
import { useParams,useNavigate } from 'react-router-dom';
import alertService from "../services/sweetAlert";
import './Loka.css';

export default function Loka() {
  const { currentUser } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]); // Track recent online avatars
  const [newMessage, setNewMessage] = useState(''); // Message input value
  const [taggedUser, setTaggedUser] = useState(''); // Store the tagged user
  const { room } = useParams(); // Get the room name from the URL
  const socket = createSocket(); // Initialize the socket connection
  const cdn = 'https://files.geetsuhane.com/';
  const [backgroundImage, setBackgroundImage] = useState('https://files.geetsuhane.com/loka/vishnu5.gif');
  const navigate = useNavigate()

  

   // Function to dynamically set the background based on the room
   useEffect(() => {
  
    switch (room.toLowerCase()) {
      case 'vishnu':
        setBackgroundImage("url('https://files.geetsuhane.com/loka/vishnu5.gif')");       
        break;
      case 'shiv':
        setBackgroundImage("url('https://files.geetsuhane.com/loka/shiva.jpeg')");       
        break;
      case 'bramh':
        setBackgroundImage("url('https://files.geetsuhane.com/loka/bramha.jpeg')");       
        break;
      default:
        setBackgroundImage("url('https://files.geetsuhane.com/loka/vishnu5.gif')");
    }
  }, [room,currentUser]); // Re-run the effect if the room changes


  useEffect(() => {
    if (room) {
      if(currentUser) {
        switch (room.toLowerCase()) {
          case 'vishnu':
            if(currentUser?.gname==='Rockers' || currentUser?.gname==='Tigers' || currentUser?.gname==='Global Stars' || currentUser?.SID==='008') {
            } else {
              alertService.info('Not authorized', 'Only Rockers, Tigers and Global Stars are allowed in this room');
              navigate('/gupshup/'+currentUser?.gname);
            }
            break;
          case 'shiv':
            if(currentUser?.gname==='Bandish' || currentUser?.gname==='Dazzlers' || currentUser?.gname==='Incredibles' || currentUser?.SID==='008') {
            } else {
              alertService.info('Not authorized', 'Only Bandish, Dazzlers and Incredibles are allowed in this room');
              navigate('/gupshup/'+currentUser?.gname);
    
            }
            break;
          case 'bramh':
            if(currentUser?.gname==='Singing Stars' || currentUser?.gname==='Sargam' || currentUser?.gname==='Rhythm' || currentUser?.SID==='008') {
            } else {
              alertService.info('Not authorized', 'Only Sargam, Singing Stars and Rhythm are allowed in this room');
              navigate('/gupshup/'+currentUser?.gname);             
            }
            break;
        }
      }
        socket.emit('joinRoom', { room, userId: currentUser?.SID, picture: currentUser?.picture });
      
      socket.on('roomUsers', (users) => {
        const uniqueUsers = users.filter(
          (user, index, self) => index === self.findIndex(u => u.userId === user.userId)
        );
        setOnlineUsers(uniqueUsers); // Update the list of online users with unique ones
      });

      socket.on('message', (newMessage) => {
        setMessages((prevMessages) => [...prevMessages, newMessage].slice(-10)); // Show the latest 10 messages
      });

      return () => {
        socket.off('roomUsers');
        socket.off('message');
      };
    }
  }, [room, currentUser]);

  // Send message when Enter is pressed
  const sendMessage = (e) => {
    if (e.key === 'Enter' && newMessage && room) {
      e.preventDefault(); // Prevent default form behavior
      const messageData = {
        room,
        message: `${taggedUser} ${newMessage}`.trim(), // Only tag if another user is clicked
        userId: currentUser?.SID,
        name: currentUser?.name, // Include sender's name
        picture: currentUser?.picture,
      };
      socket.emit('sendMessage', messageData);
      setNewMessage(''); // Clear input after sending
      setTaggedUser(''); // Clear tagged user
    }
  };
  async function getMediaStream() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      return stream; // Return the audio stream
    } catch (err) {
      console.error('Error accessing media devices:', err);
    }
  }
  

  const handleTagUser = (user) => {
    if (user.userId !== currentUser.SID) {
      setTaggedUser(`@${user.name}`);
    } else {
      setTaggedUser(''); // Clear tag if it's the current user
    }
  };

// Function to distribute avatars in rows, ensuring they stay within the background
const getAvatarPosition = (index) => {
  const avatarsPerRow = 5; // Limit avatars per row
  const row = Math.floor(index / avatarsPerRow); // Calculate row based on index
  const col = index % avatarsPerRow; // Position within the row

  // Keep the bottom-most row at around 85% to prevent overflow
  const verticalOffset = 85 - (row * 10); // 85% is near the bottom, stack new rows upward
  const horizontalSpacing = 18; // Horizontal space between avatars

  return {
    top: `${verticalOffset}%`, // Ensure avatars don't overflow the bottom
    left: `${10 + col * horizontalSpacing}%`, // Evenly distribute avatars horizontally
  };
};



  return (
    <Fragment>
    <div className="virtual-world" style={{ backgroundImage: backgroundImage }}>
    {onlineUsers.map((user, index) => (
          user?.userId && user?.picture && ( // Ensure valid user data
            <div
              key={user?.userId}
              className="avatar-container"
              style={getAvatarPosition(index)}
              onClick={() => handleTagUser(user)} // Tag the user only if it's another user
            >
              <div
                className="avatarlok"
                style={{
                  backgroundImage: `url(${cdn + 'users/' + user?.picture})`,
                }}
              />

              {/* Floating message with sender's name */}
              {messages
                .filter((msg) => msg.userId === user?.userId)
                .slice(-1) // Get the last message
                .map((msg, idx) => (
                  <div key={idx} className="chat-bubble">
                    <span className="message-sender">{msg.name}:</span> {/* Sender's name */}
                    <span className="message-content">{msg.message}</span>
                  </div>
                ))}

              {/* Input field below the current user's avatar */}
              {user.userId === currentUser.SID && (
                <input
                  type="text"
                  className="message-input-below-avatar"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  onKeyDown={sendMessage} // Send message when Enter is pressed
                  placeholder={taggedUser ? `Reply to ${taggedUser}` : 'Type your message'}
                />
              )}
            </div>
          )
        ))}
      </div>
    </Fragment>
  );
}
