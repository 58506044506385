import React, { Component , Fragment, useState,useEffect } from "react";
import { Link,useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/authContext";
import { useContext } from "react";
import profileService from "../services/profile.service";
import alertService from '../services/sweetAlert';
import Auction from "../components/Auction";



export default function Income() {

    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [cash,setCash] =useState(0);
    const [lastTapTime, setLastTapTime] = useState(0);


if (currentUser?.id) { 
} else {
  navigate("/login");
}
   
    var checkCash = async (actiontype,available,qty,cash) => {
        const currentTime = Date.now();
        if (currentTime - lastTapTime < 1000) {
        return;
        }
        setLastTapTime(currentTime);

        console.log(available);
       if(actiontype=="coupon") {
        
        alertService.error(
            'Coupons',
            'Currently we do not have any offer for coins exchange!'
          );
          return;
       } else {
            if(available<cash) {
                alertService.error(
                    'Coins Exchange',
                    'Currently you do not have enough GS Silver!'
                  )   
                  return;
            } else {
                const confirmed = await alertService.confirm(
                    'Coins Exchange',
                    'Are you sure you want to exchange '+cash+' GS Silver for '+qty+ ' GS Gold?'
                  )
              
                if (confirmed) {
                var updateCoins = await profileService.getResult({
                    qry: `CALL sp_exchangeCoins('${currentUser?.id}', ${qty}, ${cash}); `
                });
                console.log(updateCoins);
                if(updateCoins) {
                updateCash();
                alertService.info(
                    'Coins Exchange',
                    'Exchange of '+qty +' is successful!'
                  ) 
                } else {
                    alertService.error(
                        'Coins Exchange',
                        'Opps some error, please try after some time or contact GS Support'
                      ) 
                }  
            }
      }
    }
}
    var updateCash = async () => {
       //console.log(currentUser)
        var getCash = await profileService.getResult({
            qry: `CALL sp_getIncome('${currentUser?.id}');`
        });
       setCash(getCash.length?getCash[0][0]:0);
       //console.log(getCash);
         
     }
 
  
   // console.log(currentUser);
  
    if(currentUser==null)
    {
      navigate('/login')
    } 
    const withdraw = async() => {
        alertService.info(
          'Withdraw',
          'You need minimum Rs 1000 to withdraw, terms and consitions apply!'
        ) 
  
    };
    useEffect(() => {
        async function updateData() {
         //   console.log(currentUser)
             updateCash();
        }
        updateData();
      }, []);
// class Account extends Component {
    
        return (
            <Fragment> 
     
 
            <div className="main-content right-chat-active bg-white">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0" >
                        <div className="row">
                            <div className="col-xl-12 cart-wrapper mb-4">
                                <div className="row">
                                    <div className="col-lg-5 mb-3">
                                        <div className="card p-md-5 p-4 bg-primary-gradiant rounded-3 shadow-xss bg-pattern border-0 overflow-hidden">
                                            <div className="bg-pattern-div"></div>
                                            <h2 className="display2-size display2-md-size fw-700 text-white mb-0 mt-0">Wallet <span className="fw-700 ls-3 text-grey-200 font-xsssss mt-2 d-block"></span></h2>
                                        </div>
                                        <div className="text-center pt-2"> <img src="https://files.geetsuhane.com/gifts/gscash.jpg" alt="GSC" className="w25 rounded-3 " /> <span className="font-xssss  strong "> GS Rewards: {cash?cash.rupee:0}</span> 
                                        <a onClick={() => withdraw()}
                             className="mt-0 btn pt-1 pb-2 ps-3 pe-3 ms-1 ls-3 d-inline-block  bg-gold-gradiant font-xsssss fw-700  text-white text-center"> 
                             Withdraw </a>
                             <div className="font-xssss">Only active users can withdraw upto 50% GSC after GS Managemnet's approval, 
                             50% will be resevred. Terms and conditions apply. GS can decline the request without any prior notice. Terminated users will loose all GSC automatically.</div>
                                        </div>
                                    </div>
                                   
                                </div>
                                {/* <div><Auction></Auction></div> */}

                                <div className="row">
                                    <div className="col-lg-5 mb-3">
                                        <div className="table-content table-responsive">
                                            <center>
                                            <div> <img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> <span className="strong"> Available GS Silver: {cash?cash.available_cash:0}</span> </div>
                                            <div> <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w25 rounded-3 " /> <span className=" font-xss"> Available GS Gold: {cash?cash.available_gold:0}</span> </div>

                                            </center>
                                            <hr className="w-100"></hr>
                                           
                                            <table className="table text-center">
                                               
                                                <tbody>
                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">100</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,100,500)}  className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">500 <img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">200</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,200,1000)}  className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">1000<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">500</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a  onClick={()=> checkCash('',cash?cash.available_cash:0,500,2500)} className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">2500<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">1000</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,1000,5000)}  className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">5000<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">5000</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,5000,25000)} className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">25000<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>
                                                
                                                  
                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">100000</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,100000,500000)} className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">500000<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>

                                                   
                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">500000</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,500000,2500000)} className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">2500000<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">1000000</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,1000000,5000000)} className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">5000000<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">5000000</span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,5000000,25000000)} className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">25000000<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> </a>

                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td className="product-thumbnail text-left ps-0">
                                                            <img src="https://files.geetsuhane.com/gifts/gold.png" alt="GS Cash" className="w75 rounded-3" />
                                                            <span className="strong font-xss text-gray">{cash?.available_cash/5} </span> 
                                                        </td>                                                   
                                                      
                                                        <td className="product-total-price">
                                                           
                                                            <a onClick={()=> checkCash('',cash?cash.available_cash:0,cash?.available_cash/5,cash?.available_cash)} className="pointer update-cart bg-primary float-right text-white fw-600 text-uppercase font-xssss  rounded-3 border-size-md d-inline-block w80 p-3 text-center ls-3">{cash?cash.available_cash:0}<img src="https://files.geetsuhane.com/gifts/silver.png" alt="GS Cash" className="w25 rounded-3 " /> Max </a>

                                                        </td>
                                                    </tr>
                                                  
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="coupon float-left mb-2">
                                            <input type="text" className="input-code form-control h60 p-3" placeholder="Coupon Code.." />
                                            <a onClick={()=> checkCash('coupon')} className="pointer bg-dark text-white fw-600 text-uppercase font-xssss border-dark border rounded-3 border-size-md d-inline-block w175 p-3 text-center ls-3">Apply Coupon</a>
                                        </div>
                                    </div>
                                    <div className="font-xssss">Note: GS Cash/Gifts/gold/silver are for this website only, it does not have any monetory value, currently we are not offering cash withdrwal or physical gifts, GS gold/cash/silver will  expiry in  month end, for more details please contact GS support!
                                    Only active users can withdraw upto 50% GSC after GS Managemnet's approval, 50% will be resevred. Terms and conditions apply. GS can decline the request without any prior notice. Terminated users will loose all GSC automatically. 
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
     
        </Fragment>
        );
        }

