import React, { useEffect, useState } from "react";
import axios from "axios";
import profileService from "../services/profile.service";
import { useNavigate,useParams } from "react-router-dom";


const RaceResults = () => {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate()
  const { rtype } = useParams(); // Get the room name from the URL


  const checkResult = async () => {
    try {
      const res = await profileService.getResult({
        qry: `CALL sp_boatRace('','${rtype}','',0)`
      }).then(r=> {
         //console.log(r?.[0]);
         setResults(r?.[0]);
      });     
    } catch (error) {
      console.error("Error fetching qual:", error);
    }
  };

    // Function to handle close button click
    const handleClose = () => {
        navigate(-1); // Redirect to the parent page (previous page)
      };
    

  useEffect(() => {
    console.log(rtype);
    async function getResult() {
     await checkResult();    
    }
    getResult();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left pe-0">           
          <table>
            <thead className="font-xssss strong bg-mini-gradiant text-white ">
              <tr>
                <th className="p-1" > Rank</th>
                <th className="p-1">{rtype==='result'?'Name':'Planet'}</th>
                <th className="p-1">{rtype==='result'?'Planet':'Races'}</th>
                <th className="p-2">{rtype==='result'?'Score':'Wins'}</th>           
                <th className="p-1">{rtype==='result'?'Tools':'Score'}</th>
              </tr>
            </thead>
            <tbody className="font-xssss">
              {results?.map((result, index) => (
                <tr 
                  key={index}
                  className={index % 2 === 0 ? 'bg-light' : 'bg-grey'} // Apply different classes for alternate rows
                >
                  <td className="p-1">{result?.Rank}</td>
                  <td className="p-1">{rtype==='result'?result?.Name:result?.team} </td>
                  <td className="p-1">{rtype==='result'?result?.Planet:result?.races}</td>
                  <td className="p-2">{rtype==='result'?result?.Score:result?.wins}</td>
                  <td>{rtype==='result'?result?.Tools:result?.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
           {/* Close Button */}
           <div className="text-center">  <button  className="rank-button bg-mini-gradiant text-center text-white font-xsssss underline m-1"  onClick={handleClose} style={{ marginTop: "20px" }}>
        Close
      </button></div>
        
        </div>
      </div>
    </div>
  );
}  

export default RaceResults;
