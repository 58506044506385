import React, { useState, useEffect, useContext } from 'react';
import { createSocket } from '../socket'; 
import './Chessboard.css';
import profileService from '../services/profile.service';
import { AuthContext } from "../context/authContext";
import { Avatar } from "@mui/material";
import alertService from '../services/sweetAlert';


const ChessGame = () => {
  const initialBoard = [
    ["rook-black", "knight-black", "bishop-black", "queen-black", "king-black", "bishop-black", "knight-black", "rook-black"],
    ["pawn-black", "pawn-black", "pawn-black", "pawn-black", "pawn-black", "pawn-black", "pawn-black", "pawn-black"],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    [null, null, null, null, null, null, null, null],
    ["pawn-white", "pawn-white", "pawn-white", "pawn-white", "pawn-white", "pawn-white", "pawn-white", "pawn-white"],
    ["rook-white", "knight-white", "bishop-white", "queen-white", "king-white", "bishop-white", "knight-white", "rook-white"],
  ];

  const { currentUser } = useContext(AuthContext);

  const [board, setBoard] = useState(initialBoard);
  const [turn, setTurn] = useState("white");
  const [selectedPiece, setSelectedPiece] = useState(null);
  const [socket, setSocket] = useState(null); 
  const [playerColor, setPlayerColor] = useState(null);
  const [playerChoice, setPlayerChoice] = useState(null);
  const [gameId, setGameId] = useState(""); 
  const [isConnected, setIsConnected] = useState(false);
  const [availableGames, setAvailableGames] = useState(null);
  const [player1, setPlayer1] = useState(null);
  const [player2, setPlayer2] = useState(null);
  const [isCheck, setIsCheck] = useState(false);  // State to track if the player is in check
  const [isGameOver, setIsGameOver] = useState(false);  // State to track if the game is over
  const [winner, setWinner] = useState(null);  // State to track the winner of the game
  const cdn = "https://files.geetsuhane.com/";
  const [timeLeft, setTimeLeft] = useState(60);  // 60 seconds for each turn
const [intervalId, setIntervalId] = useState(null);  // Store the interval ID

  
  // Process to create or fetch chess game
  const processChess = async (ctype,gameid,uid1,uid2,u1pic,u2pic) => {
    try {
      console.log('ctype'+ctype);
      var qery='';
    if (ctype === 'createAI') {
      qery = ` CALL sp_chess('createAI', 0, '${currentUser?.SID}', '', '', 'white', '')`;
    } else if (ctype === 'create') {
        qery = ` CALL sp_chess('${ctype}',0,'${currentUser?.SID}','','','white','')`;
      } else if(ctype === 'play') {
        qery = ` CALL sp_chess('${ctype}',0,'${currentUser?.id}','','','','')`;
      } else if(ctype === 'winner') {
        qery = ` CALL sp_chess('${ctype}',${gameid},'','','','${u1pic}','')`;
       } else if(ctype==='rejoin') {
        //const newGameId = `game${gameid}`;
        await updateGameStatus(gameid);
         setGameId(gameid);
          setPlayerChoice('in-game'); // Update UI state to switch to game
          if(uid1===currentUser?.SID) {
            setPlayerColor('white'); 
          } else {
            setPlayerColor('black'); 
          }
          setPlayer1(u1pic);
          setPlayer2(u2pic);
          socket.emit('joinGame', { gameId: gameid, playerId: currentUser?.SID,playerPic:currentUser?.picture,isAi: false });
       } else if (ctype === 'join') {
        //const newGameId = `game${gameid}`;
        if(u1pic===currentUser?.picture) {
          alertService.error('Self Join', 'You can not join yourself. Please invite someone.');
          return null;
        }
          qery = ` CALL sp_chess('${ctype}',${gameid},'','${currentUser?.SID}','','','black')`;
          setGameId(gameid);
          setPlayer1(u1pic);
          setPlayerColor('black'); 
          setPlayer2(currentUser?.picture);
          setPlayerChoice('in-game'); // Update UI state to switch to game
          socket.emit('joinGame', { gameId: gameid, playerId: currentUser?.SID,playerPic:currentUser?.picture,isAi: false });
       } else if(ctype==='watch') {
        //const newGameId = `game${gameid}`;
        await updateGameStatus(gameid);
        setPlayerChoice('in-game'); // Update UI state to switch to game
        setPlayer1(u1pic);
        setPlayer2(u2pic);
        socket.emit('joinGame', { gameId: gameid });
      } else if(ctype ==='all') {
        qery = ` CALL sp_chess('${ctype}',0,'','','','','')`;
        }
        if(qery!='') {
          const chessData = await profileService.getResult({
            qry: qery
          });
          if (chessData?.length > 0) {
            if (ctype === 'createAI') {
              
              const newGameId = chessData?.[0][0]?.gameid;
              setGameId(newGameId);
              console.log('newGameId'+newGameId);
              setPlayer1(currentUser?.picture);
              setPlayerColor('white');
              setPlayerChoice('in-game');
              socket.emit('joinGame', { gameId: newGameId, playerId: currentUser?.SID, playerPic:currentUser?.picture,isAi: true });
              setPlayer2('gsai.png');
            } else if (ctype === 'create' ) {
              //const newGameId = `game${chessData?.[0][0]?.gameid}`;
              if(chessData?.[0][0]?.res==='exists') {
                alertService.error('Game exists', 'You have already created a game. Please wait for opponent to join.');
                return null;
              }
              const newGameId = chessData?.[0][0]?.gameid;
              setGameId(newGameId);
              setPlayer1(currentUser?.picture);
              setPlayerColor('white');
              setPlayerChoice('in-game'); // Update UI state to switch to game
              socket.emit('joinGame', { gameId: newGameId, playerId: currentUser?.SID,playerPic:currentUser?.picture,isAi: false  });
              //return newGameId;
            } else if(ctype=== 'all') {
              //console.log(chessData?.[0]);
              setAvailableGames(chessData?.[0]);
            }
          } else if(ctype=== 'winner') {
            //console.log(chessData?.[0]);
            setWinner(chessData?.[0][0]?.winner);          
          }
        }
     
     
    } catch (error) {
      console.error('Error processing chess:', error);
    }
    return null;
  };

  // Initialize the socket only once
  useEffect(() => {
    if (!socket ) {
      const newSocket = createSocket(); // Create the socket connection
      setSocket(newSocket);

      newSocket.on('connect', () => {
        //console.log("Socket connected");
        setIsConnected(true);
      });

      newSocket.on('disconnect', () => {
        console.log("Socket disconnected");
        setIsConnected(false);
      });

      newSocket.on('gameState', ({ board, turn }) => {
       // console.log('gamestate updated')
        setBoard(board);
        setTurn(turn);
      });

      newSocket.on('moveMade', ({ board, turn }) => {
        console.log("Move received from server. Updating board.");
        //console.log('board'+board)
        setBoard(board);  // Update the board with the new state
        setTurn(turn);    // Update the turn
        const opponentColor = playerColor === "white" ? "black" : "white";
        if (isPlayerInCheck(board, opponentColor)) {
          setIsCheck(true);  // Show check indicator if opponent's king is in check
        } else {
          setIsCheck(false);
        }
      });

      newSocket.on('turnSwitched', ({ turn }) => {
        setTurn(turn);
        // No need to reset timeLeft here; it will be updated by the 'timerUpdate' event
      });

      newSocket.on('timerUpdate', ({ timeLeft }) => {
        setTimeLeft(timeLeft); // Update the timer based on server updates
      });
    
      newSocket.on('timerEnded', ({ gameId }) => {
        alertService.error('Time is up', 'Time is up! Switching turn.');

        // You can handle additional logic here, such as switching turns or declaring a winner
      });
    

      newSocket.on('2joined', ({playerPic}) => {
        setPlayer2(playerPic);
        console.log("player2 joined");
      });

      newSocket.on('connect_error', () => {
        console.error("Connection failed");
      });
      newSocket.on('gameOver', ({ winner }) => {
        alert(`Game Over! The winner is ${winner}.`);
        setIsGameOver(true);  // Update game state to show the game is over
      });

      return () => {
        if (newSocket) {
          newSocket.off('turnSwitched');
          newSocket.off('timerUpdate');
          newSocket.off('timerEnded');
          newSocket.off('2joined');
          newSocket.off('gameOver');
          newSocket.disconnect();
          setSocket(null); // Reset socket state
          setIsConnected(false);
        }
      };
    } 
  }, []); // Only run this when playerChoice changes

   useEffect(() => {
    const fetchGames = async () => {
      const gamesList = await processChess('all', '','','','','');
      if (gamesList) {
        setAvailableGames(gamesList); // Update availableGames state
      }
    };
  
    if (!playerChoice) {
      fetchGames(); // Fetch available games when the component is first mounted and no game has been selected
    }
  }, [playerChoice]); // Run only when playerChoice is null or "list"
  
  const isValidMove = (startRow, startCol, targetRow, targetCol, piece, board) => {
    // Pawn validation
  
    if (piece.startsWith("pawn")) {
      const direction = piece.endsWith("white") ? -1 : 1; // White pawns move up, black pawns move down
      const initialRow = piece.endsWith("white") ? 6 : 1; // Starting row for pawns
      const singleMove = targetRow === startRow + direction && targetCol === startCol && !board[targetRow][targetCol]; // Empty square
      const doubleMove = startRow === initialRow && targetRow === startRow + direction * 2 && targetCol === startCol && !board[targetRow][targetCol] && !board[startRow + direction][startCol]; // Double move from initial row
  
      // Check for single or double move in the correct direction
      if (singleMove || doubleMove) {
        return true;
      }
  
      // Check for diagonal capture
      const diagonalMove = Math.abs(startCol - targetCol) === 1 && targetRow === startRow + direction && board[targetRow][targetCol] && !board[targetRow][targetCol].startsWith(piece.slice(-5));
      if (diagonalMove) {
        return true;
      }
  
      return false; // Invalid pawn move
    }
  
    // Rook validation (moves straight)
    if (piece.startsWith("rook")) {
      if (startRow === targetRow || startCol === targetCol) {
        return isPathClear(startRow, startCol, targetRow, targetCol, board);
      }
      return false;
    }
  
    // Knight validation (moves in "L" shape)
    if (piece.startsWith("knight")) {
      //console.log('validate knight')
      const rowDiff = Math.abs(targetRow - startRow);
      const colDiff = Math.abs(targetCol - startCol);
      return (rowDiff === 2 && colDiff === 1) || (rowDiff === 1 && colDiff === 2);
    }
  
    // Bishop validation (moves diagonally)
    if (piece.startsWith("bishop")) {
      if (Math.abs(targetRow - startRow) === Math.abs(targetCol - startCol)) {
        return isPathClear(startRow, startCol, targetRow, targetCol, board);
      }
      return false;
    }
  
    // Queen validation (combines rook and bishop moves)
    if (piece.startsWith("queen")) {
      if (startRow === targetRow || startCol === targetCol || Math.abs(targetRow - startRow) === Math.abs(targetCol - startCol)) {
        return isPathClear(startRow, startCol, targetRow, targetCol, board);
      }
      return false;
    }
  
    // King validation (moves one square in any direction)
    if (piece.startsWith("king")) {
      const rowDiff = Math.abs(targetRow - startRow);
      const colDiff = Math.abs(targetCol - startCol);
      return rowDiff <= 1 && colDiff <= 1;
    }
  
    return false; // Invalid move for unknown piece type
  };
  
  // Helper function to check if the path is clear for rook, bishop, and queen
  const isPathClear = (startRow, startCol, targetRow, targetCol, board) => {
    const rowDirection = Math.sign(targetRow - startRow); // +1 for down, -1 for up, 0 for no movement
    const colDirection = Math.sign(targetCol - startCol); // +1 for right, -1 for left, 0 for no movement
  
    let row = startRow + rowDirection;
    let col = startCol + colDirection;
  
    while (row !== targetRow || col !== targetCol) {
      if (board[row][col]) {
        return false; // Path is blocked
      }
      row += rowDirection;
      col += colDirection;
    }
  
    // Check that the target square is either empty or contains an opponent's piece
    return !board[targetRow][targetCol] || board[targetRow][targetCol].slice(-5) !== board[startRow][startCol].slice(-5);
  };

  const checkKingExists = (board) => {
    let whiteKingExists = false;
    let blackKingExists = false;
  
    for (let row = 0; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        const piece = board[row][col];
        if (piece === 'king-white') {
          whiteKingExists = true;
          console.log(row, col);          
        } 
        if (piece === 'king-black') {
          console.log(row, col);          
          blackKingExists = true;
        }       
      }
    }
  
    console.log('whiteKingExists', whiteKingExists);
    console.log('blackKingExists', blackKingExists);
    if (!whiteKingExists) {
      return 'black'; // Black wins
    }
    if (!blackKingExists) {
      return 'white'; // White wins
    } 
    return null; // Both kings still exist
  };

  const updateGameStatus = async (gameId) => { 
    const kingStatus = checkKingExists(board);
    console.log('kingStatus', kingStatus);
    if (kingStatus) {
      setWinner(kingStatus); // Declare the winner if a king is missing
      setIsGameOver(true);    
      processChess('winner',gameId,'','',kingStatus,'');        
    }
  }
  

  const handleSquareClick = (row, col) => {
    if (isGameOver) return;  // Stop if the game is over
  
    const piece = board[row][col];  // Get the piece at the clicked square
    if (!socket || !isConnected) return;  // Check if the socket is connected
  
    // Prevent making a move if it's not the player's turn
    if (turn !== playerColor) {
      alertService.error('Not your turn', 'You cannot make a move right now.');
      return;
    }
  
    // If a piece is already selected, check if it's a valid move
    if (selectedPiece) {
      // Check if the piece clicked is of the same color; if yes, reselect the new piece
      if (piece && piece.endsWith(playerColor)) {
        setSelectedPiece({ row, col, piece });  // Select the new piece
        return;  // Exit so it doesn't try to validate as a move
      }
  
      // Validate if the move is allowed
      if (isValidMove(selectedPiece.row, selectedPiece.col, row, col, selectedPiece.piece, board)) {
        // Emit the move event through Socket.IO
        socket.emit('makeMove', {
          startRow: selectedPiece.row,
          startCol: selectedPiece.col,
          targetRow: row,
          targetCol: col,
          player: playerColor,
          gameId: gameId,
        });
  
        setSelectedPiece(null);  // Deselect the piece after making the move
        const points =  processChess('play', '','','','','');

  
        const opponentColor = playerColor === "white" ? "black" : "white";
  
        // Check if the move puts the opponent in check
        if (isPlayerInCheck(board, opponentColor)) {
          setIsCheck(true);
          socket.emit('kingInCheck', { gameId, player: opponentColor });
        } else {
          setIsCheck(false);
        }
  
        // Check for checkmate
        if (isCheckmate(board, playerColor)) {
          setIsGameOver(true);
          setWinner(playerColor);
          socket.emit('gameOver', { gameId, winner: playerColor });
        } else {
          updateGameStatus(gameId);
        }
      } else {
        setSelectedPiece(null);  // Deselect if the move is invalid
        alertService.error('Invalid move', 'This move is not valid. Please try again.');
      }
    } else if (piece && piece.endsWith(playerColor)) {
      // Select the piece if no piece is currently selected
      setSelectedPiece({ row, col, piece });
    }
  };
  
  
  
  const validateMoveResolvesCheck = (startRow, startCol, targetRow, targetCol, piece, board) => {
    // Create a copy of the board to simulate the move
    const newBoard = JSON.parse(JSON.stringify(board));
  
    // Move the piece to the target position on the copied board
    newBoard[targetRow][targetCol] = newBoard[startRow][startCol];
    newBoard[startRow][startCol] = null;
  
    // Check if after this move, the current player's king is still in check
    return !isPlayerInCheck(newBoard, piece.endsWith("white") ? "white" : "black");
  };
  
  
  const isPlayerInCheck = (board, playerColor) => {
    let kingPosition = null;
  
    // Find the player's king position
    outerLoop:
    for (let row = 0; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        const piece = board[row][col];
        if (piece === `king-${playerColor}`) {
          kingPosition = { row, col };
          break outerLoop; // Break both loops once the king is found
        }
      }
    }
  
    if (!kingPosition) return false; // I king found, return false
  
    const opponentColor = playerColor === "white" ? "black" : "white";
  
    // Check if any opponent piece can move to the king's position
    for (let row = 0; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        const piece = board[row][col];
  
        // Only check the opponent's pieces
        if (piece && piece.endsWith(opponentColor)) {
          // Check if this piece can move to the king's position
          if (isValidMove(row, col, kingPosition.row, kingPosition.col, piece, board)) {
            console.log(`${piece} at (${row},${col}) can attack the king at (${kingPosition.row},${kingPosition.col})`);
            return true; // King is in check
          }
        }
      }
    }
  
    return false; // King is not in check
  };
  
  
  

  const isCheckmate = (board, playerColor) => {
    // If the current player's king is not in check, it can't be checkmate
    if (!isPlayerInCheck(board, playerColor)) return false;
  
    // Try all possible moves for every piece of the current player
    for (let row = 0; row < 8; row++) {
      for (let col = 0; col < 8; col++) {
        const piece = board[row][col];
  
        // If it's the current player's piece
        if (piece && piece.endsWith(playerColor)) {
          // Try moving the piece to all possible positions
          for (let targetRow = 0; targetRow < 8; targetRow++) {
            for (let targetCol = 0; targetCol < 8; targetCol++) {
              // Check if this move is valid
              if (isValidMove(row, col, targetRow, targetCol, piece, board)) {
                // Simulate the move by creating a copy of the board
                const newBoard = JSON.parse(JSON.stringify(board));
                
                // Move the piece on the new board
                newBoard[targetRow][targetCol] = newBoard[row][col];
                newBoard[row][col] = null;
                
                // If the piece is the king, update its position
                const newKingPos = (piece === `king-${playerColor}`) 
                  ? { row: targetRow, col: targetCol } 
                  : null;
                  
                // Check if this move resolves the check
                if (!isPlayerInCheck(newBoard, playerColor, newKingPos)) {
                  return false; // A valid move exists that resolves the check, so it's not checkmate
                }
              }
            }
          }
        }
      }
    }
  
    // If no valid move resolves the check, it's checkmate
    console.log('Checkmate');
    return true;
  };
  
  // Render the game selection menu
  const renderMenu = () => (
    <div className="game-menu">
      <div className="text-white text-center font-xsss counter rounded-xl bg-primary-gradiant me-3" 
           onClick={() => processChess('create', '', '', '', '', '')}>
        Start New Game
      </div>
      <div className='p-1'></div>
      <div className="text-white text-center font-xsss counter rounded-xl bg-mini-gradiant me-3"
         onClick={() => processChess('createAI', '', '', '', '', '')}>
      Play with AI
    </div>
  
      <div className='font-xsss pt-2'>
        {availableGames?.length > 0 && availableGames.map((value, idx) => (
          <div key={idx} className="game-entry">
            <div className="avatar-row">
              <Avatar sx={{ width: 60, height: 60 }} src={cdn + 'users/' + value?.u1pic} />
              {value?.u2pic && (
                <div className="avatar-vs">
                  <span>vs</span>
                  <Avatar sx={{ width: 60, height: 60 }} src={cdn + 'users/' + value?.u2pic} />
                </div>
              )}
            </div>
  
            <div className="action-icons-row">
              {!value?.uname2 ? (
                <i onClick={() => processChess('join', value?.gameid, '', '', value?.u1pic, value?.u2pic)}
                   className="bg-primary feather-user-plus pointer font-xsss text-white text-center counter btn-round-xs bg-black me-3"></i>
              ) : (
                (value?.uid1 === currentUser?.SID || value?.uid2 === currentUser?.SID) ? (
                  <i onClick={() => processChess('rejoin', value?.gameid, value?.uid1, value?.uid2, value?.u1pic, value?.u2pic)}
                     className="feather-log-in pointer font-xsss text-white text-center counter btn-round-xs bg-black me-3"></i>
                ) : (
                  <i onClick={() => processChess('watch', value?.gameid, value?.uid1, value?.uid2, value?.u1pic, value?.u2pic)}
                     className="feather-eye pointer font-xsss text-white text-center counter btn-round-xs bg-black me-3"></i>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  
  

// Main render
// Main render
return (
  <div className="main-content">
    <div className="main-content-chess">
      {!playerChoice ? (
        renderMenu() // Show the game list or new game menu if no playerChoice is selected
      ) : playerChoice === "in-game" ? (
        <>
        <div >
          {/* Display player information and whose turn it is */}
          <div className='avatar-row'>
    
              <Avatar sx={{ width: 30, height: 35 }} src={cdn + 'users/' + player1} />
              <span className="font-xsss p-2">White</span>
    
          
              <Avatar sx={{ width: 35, height: 35 }} src={cdn + 'users/' + player2} />
              <span className="font-xsss p-2">Black</span>

    
              <span className="font-xsss strong">Turn: </span> 
              {turn === "white" ? (
                <Avatar sx={{ width: 35, height: 35 }} src={cdn + 'users/' + player1} />
              ) : (
                <Avatar sx={{ width: 35, height: 35 }} src={cdn + 'users/' + player2} />
              )}

          </div>
          <div className="font-xssss strong text-white bg-mini-gradiant text-center">Time left: {timeLeft}s</div>  {/* Display remaining time */}

          {/* Game Over Message */}
          {isGameOver && (
            <div className="game-over">
              <h2>Game Over</h2>
              <p>Winner: {winner}</p>
            </div>
          )}

          {/* Chessboard */}
          <div className={`chessboard ${playerColor === 'black' ? 'flipped' : ''}`}>
            {board.map((row, rowIndex) => (
              row.map((piece, colIndex) => (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={`square ${(rowIndex + colIndex) % 2 === 0 ? "light" : "dark"} ${
                    selectedPiece?.row === rowIndex && selectedPiece?.col === colIndex ? "selected" : ""
                  } ${playerColor === 'black' ? 'flipped' : ''} ${
                    isCheck && piece === `king-${turn}` ? "check" : ""
                  }`} // Flash the king if in check
                  onClick={() => !isGameOver && handleSquareClick(rowIndex, colIndex)} // Disable clicks if game is over
                >
                  {piece && <img src={`https://files.geetsuhane.com/chess/${piece}.png`} alt={piece} className="piece" />}
                </div>
              ))
            ))}
          </div>

          {/* Game Over Message */}
          {isGameOver && (
            <div className="game-over">
              <h2>Game Over</h2>
              <p>Winner: {winner}</p>
            </div>
          )}
        </div>
      </>
    ) : (
      <div>Loading...</div>
    )}
  </div>
</div>
);
};

export default ChessGame;
